// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-david-templeton-tsx": () => import("./../../../src/pages/about/david-templeton.tsx" /* webpackChunkName: "component---src-pages-about-david-templeton-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-disclosure-tsx": () => import("./../../../src/pages/disclosure.tsx" /* webpackChunkName: "component---src-pages-disclosure-tsx" */),
  "component---src-pages-financial-planning-investment-services-tsx": () => import("./../../../src/pages/financial-planning/investment-services.tsx" /* webpackChunkName: "component---src-pages-financial-planning-investment-services-tsx" */),
  "component---src-pages-financial-planning-retirement-planning-tsx": () => import("./../../../src/pages/financial-planning/retirement-planning.tsx" /* webpackChunkName: "component---src-pages-financial-planning-retirement-planning-tsx" */),
  "component---src-pages-financial-planning-risk-protection-tsx": () => import("./../../../src/pages/financial-planning/risk-protection.tsx" /* webpackChunkName: "component---src-pages-financial-planning-risk-protection-tsx" */),
  "component---src-pages-financial-planning-safe-money-tsx": () => import("./../../../src/pages/financial-planning/safe-money.tsx" /* webpackChunkName: "component---src-pages-financial-planning-safe-money-tsx" */),
  "component---src-pages-financial-planning-tsx": () => import("./../../../src/pages/financial-planning.tsx" /* webpackChunkName: "component---src-pages-financial-planning-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-videos-tsx": () => import("./../../../src/pages/videos.tsx" /* webpackChunkName: "component---src-pages-videos-tsx" */)
}

